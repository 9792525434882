export const globalBlurDataUrl =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOUVY+oBwACIgEdJb/dVwAAAABJRU5ErkJggg==';

export const aacLogoHorizontal =
    'https://admin.americanaddictioncenters.org/aac-common-bucket/aac-lockup-horizontal-color-v2.svg';

// General Inqueries Phone Number
export const generalInquiriesPhone = '(615) 732-1616';
export const generalInquiriesPhoneLink = 'tel:+1-615-732-1616';

// Media & PR Phone Number
export const mediaPhone = '(615) 587-7728';
export const mediaPhoneLink = 'tel:+1-615-587-7728';
export const mediaEmail = 'mediarequest@contactAAC.com';
export const mediaEmailLink = 'mailto:mediarequest@contactAAC.com';

//form error message
export const formMessage = {
    error: 'There was an issue with your request. Please contact us by phone or try back later.',
    success:
        'We have received your message. We will be in touch within the next 48 hours.',
};

// scholarship email address
export const scholarshipToEmail = 'scholarship@contactaac.com';
export const scholarshipFromEmail = 'scholarship@americanaddictioncenters.org';

//corporate phone number
export const corporatePhone = '(888) 987-1784';
export const corporatePhoneLink = 'tel:+1-888-987-1784';

// social media links
export const twitterLink = 'https://twitter.com/AAC_Tweet';
export const facebookLink = 'https://www.facebook.com/AmericanAddictionCenters';
export const linkedinLink =
    'https://www.linkedin.com/company/american-addiction-centers/';
export const instagramLink = 'https://www.instagram.com/americanaddictioncenters/';
export const tiktokLink = 'https://www.tiktok.com/@americanaddictioncenters';
export const youtubeLink = 'https://www.youtube.com/@Americanaddictioncenters';

export const facilityTherapiesUrl =
    'https://admin.oxfordtreatment.com/wp-json/wp/v2/therapy-comparison-chart';
export const facilityAmenetiesUrl =
    'https://admin.oxfordtreatment.com/wp-json/wp/v2/amenities-comparison-chart';

export const treatmentCenterShortNames = [
    'Desert Hope',
    'Greenhouse',
    'Laguna',
    'Oxford',
    'Recovery First',
    'River Oaks',
    'AdCare',
];

export const insuranceDisclaimer =
    '<strong>Insurance Disclaimer:</strong> American Addiction Centers may attempt to verify your health insurance benefits and/or necessary authorizations on your behalf. Please note, this is only a quote of benefits and/or authorization. We cannot guarantee payment or verification eligibility as conveyed by your health insurance provider will be accurate and complete. Payment of benefits are subject to all terms, conditions, limitations, and exclusions of the member\'s contract at time of service. Your health insurance company will only pay for services that it determines to be "reasonable and necessary." American Addiction Centers will make every effort to have all services preauthorized by your health insurance company. If your health insurance company determines that a particular service is not reasonable and necessary, or that a particular service is not covered under your plan, your insurer will deny payment for that service and it will become your responsibility.';

/**
 * Insurance code types from vtx api response
 */
export const approvedInsuranceTypes = [
    {code: 'HM', label: 'Health Maintenance Organization (HMO)'},
    {code: 'PR', label: 'Preferred Provider Organization (PPO)'},
];

// US States Array
export const salesforceStateOptions = [
    {title: 'Alabama', value: 'Alabama'},
    {title: 'Alaska', value: 'Alaska'},
    {title: 'Arizona', value: 'Arizona'},
    {title: 'Arkansas', value: 'Arkansas'},
    {title: 'California', value: 'California'},
    {title: 'Colorado', value: 'Colorado'},
    {title: 'Connecticut', value: 'Connecticut'},
    {title: 'Delaware', value: 'Deleware'},
    {title: 'Florida', value: 'Florida'},
    {title: 'Georgia', value: 'Georgia'},
    {title: 'Hawaii', value: 'Hawaii'},
    {title: 'Idaho', value: 'Idaho'},
    {title: 'Illinois', value: 'Illinois'},
    {title: 'Indiana', value: 'Indiana'},
    {title: 'Iowa', value: 'Iowa'},
    {title: 'Kansas', value: 'Kansas'},
    {title: 'Kentucky', value: 'Kentucky'},
    {title: 'Louisiana', value: 'Louisiana'},
    {title: 'Maine', value: 'Maine'},
    {title: 'Maryland', value: 'Maryland'},
    {title: 'Massachusetts', value: 'Massachusetts'},
    {title: 'Michigan', value: 'Michigan'},
    {title: 'Minnesota', value: 'Minnesota'},
    {title: 'Mississippi', value: 'Mississippi'},
    {title: 'Missouri', value: 'Missouri'},
    {title: 'Montana', value: 'Montana'},
    {title: 'Nebraska', value: 'Nebraska'},
    {title: 'Nevada', value: 'Nevada'},
    {title: 'New Hampshire', value: 'New Hampshire'},
    {title: 'New Jersey', value: 'New Jersey'},
    {title: 'New Mexico', value: 'New Mexico'},
    {title: 'New York', value: 'New York'},
    {title: 'North Carolina', value: 'North Carolina'},
    {title: 'North Dakota', value: 'North Dakota'},
    {title: 'Ohio', value: 'Ohio'},
    {title: 'Oklahoma', value: 'Oklahoma'},
    {title: 'Oregon', value: 'Oregon'},
    {title: 'Pennsylvania', value: 'Pennsylvania'},
    {title: 'Rhode Island', value: 'Rhode Island'},
    {title: 'South Carolina', value: 'South Carolina'},
    {title: 'South Dakota', value: 'South Dakota'},
    {title: 'Tennessee', value: 'Tennessee'},
    {title: 'Texas', value: 'Texas'},
    {title: 'Utah', value: 'Utah'},
    {title: 'Vermont', value: 'Vermont'},
    {title: 'Virginia', value: 'Virginia'},
    {title: 'Washington', value: 'Washington'},
    {title: 'West Virginia', value: 'West Virginia'},
    {title: 'Wisconsin', value: 'Wisconsin'},
    {title: 'Wyoming', value: 'Wyoming'},
];
export const usStates = [
    {name: 'Alabama', abv: 'AL'},
    {name: 'Alaska', abv: 'AK'},
    {name: 'Arizona', abv: 'AZ'},
    {name: 'Arkansas', abv: 'AR'},
    {name: 'California', abv: 'CA'},
    {name: 'Colorado', abv: 'CO'},
    {name: 'Connecticut', abv: 'CT'},
    {name: 'Delaware', abv: 'DE'},
    {name: 'District of Columbia', abv: 'DC'},
    {name: 'Florida', abv: 'FL'},
    {name: 'Georgia', abv: 'GA'},
    {name: 'Hawaii', abv: 'HI'},
    {name: 'Idaho', abv: 'ID'},
    {name: 'Illinois', abv: 'IL'},
    {name: 'Indiana', abv: 'IN'},
    {name: 'Iowa', abv: 'IA'},
    {name: 'Kansas', abv: 'KS'},
    {name: 'Kentucky', abv: 'KY'},
    {name: 'Louisiana', abv: 'LA'},
    {name: 'Maine', abv: 'ME'},
    {name: 'Maryland', abv: 'MD'},
    {name: 'Massachusetts', abv: 'MA'},
    {name: 'Michigan', abv: 'MI'},
    {name: 'Minnesota', abv: 'MN'},
    {name: 'Mississippi', abv: 'MS'},
    {name: 'Missouri', abv: 'MO'},
    {name: 'Montana', abv: 'MT'},
    {name: 'Nebraska', abv: 'NE'},
    {name: 'Nevada', abv: 'NV'},
    {name: 'New Hampshire', abv: 'NH'},
    {name: 'New Jersey', abv: 'NJ'},
    {name: 'New Mexico', abv: 'NM'},
    {name: 'New York', abv: 'NY'},
    {name: 'North Carolina', abv: 'NC'},
    {name: 'North Dakota', abv: 'ND'},
    {name: 'Ohio', abv: 'OH'},
    {name: 'Oklahoma', abv: 'OK'},
    {name: 'Oregon', abv: 'OR'},
    {name: 'Pennsylvania', abv: 'PA'},
    {name: 'Puerto Rico', abv: 'PR'},
    {name: 'Rhode Island', abv: 'RI'},
    {name: 'South Carolina', abv: 'SC'},
    {name: 'South Dakota', abv: 'SD'},
    {name: 'Tennessee', abv: 'TN'},
    {name: 'Texas', abv: 'TX'},
    {name: 'Utah', abv: 'UT'},
    {name: 'Vermont', abv: 'VT'},
    {name: 'Virginia', abv: 'VA'},
    {name: 'Washington', abv: 'WA'},
    {name: 'West Virginia', abv: 'WV'},
    {name: 'Wisconsin', abv: 'WI'},
    {name: 'Wyoming', abv: 'WY'},
];

// Dynamic Keyword lists

export const dynamicProviders = [
    'aetna',
    'amerigroup',
    'amerihealth-insurance',
    'anthem',
    'avmed-insurance',
    'carelon-behavioral-health',
    'blue-cross-blue-shield-association',
    'cigna-insurance',
    'emblemhealth-insurance',
    'first-health',
    'geisinger-insurance',
    'harvard-pilgrim-insurance',
    'health-plan-of-nevada',
    'humana',
    'kaiser-permanente',
    'kemper-direct',
    'magellan-insurance',
    'meritain-insurance',
    'oxford-health-insurance',
    'providence-health-plan',
    'rocky-mountain-hmo',
    'sierra-health-and-life-insurance',
    'state-farm',
    'tricare',
    'tufts-health-plan',
    'unitedhealth-group',
    'upmc-insurance',
    'veterans-choice-program',
    'zelis',
    '1199seiu-funds',
];

export const dynamicSubstances = [
    'alcohol',
    'ambien',
    'ativan',
    'barbiturate',
    'benzodiazepine',
    'cocaine',
    'codine',
    'crack',
    'fentanyl',
    'heroin',
    'inhalants',
    'marijuana',
    'meth',
    'opiate',
    'oxycontin',
    'prescription-drugs',
    'stimulant-drugs',
    'suboxone',
    'vicodin',
    'valium',
    'xanax',
];

export const dynamicFacilities = [
    'adcare-rhode-island-outpatient',
    'adcare-hospital-outpatient',
    'adcare-hospital',
    'laguna-treatment-center',
    'river-oaks',
    'greenhouse',
    'desert-hope',
    'oxford',
    'adcare-rhode-island',
    'recovery-first-hollywood',
    'resolutions',
];
